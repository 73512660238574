import React, { useState, useRef } from "react";
import SignaturePad from "react-signature-canvas";

function Signature() {
  const [imageURL, setImageURL] = useState(null); // create a state that will contain our image url

  const sigCanvas = useRef({});

  /* a function that uses the canvas ref to clear the canvas
  via a method given by react-signature-canvas */
  const clear = () => sigCanvas.current.clear();

  /* a function that uses the canvas ref to trim the canvas
  from white spaces via a method given by react-signature-canvas
  then saves it in our state */
  const save = () =>
    setImageURL(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));

  return (
    <div>
      <SignaturePad
        ref={sigCanvas}
        backgroundColor='silver'
        canvasProps={{width: 200, height: 75, className: 'sigCanvas'}}
      />
    </div>
  );
}

export default Signature;
