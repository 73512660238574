import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/styles';
import fire from '../../firebase.js';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  dialog: {
    maxWidth: 350 + 'px',
    minWidth: 290 + 'px',
    margin: 'auto',
  },
  alert: {
    minWidth: 290 + 'px',
  },
  navLink: {
    color: theme.palette.primary.main,
    fontSize: 1 + 'rem',
  },
  progess: {
    position: 'absolute',
  },
}));

export default function PasswordReset(props) {
  const classes = useStyles();
  const [success, setSuccess] = React.useState(false);
  const [warning, setWarning] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [warningMessage, setWarningMessage] = React.useState('');

  let mounted = React.useRef(false);
  React.useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  const submit = async (event) => {
    if (mounted.current) {
      setLoading(true);
      setSuccess(false);
      setWarning(false);
    }

    await fire
      .auth()
      .sendPasswordResetEmail(props.email)
      .then((response) => {
        if (mounted.current) {
          setSuccess(true);
          setWarning(false);
          setWarningMessage('');
          props.close();
        }
      })
      .catch((error) => {
        if (mounted.current) {
          setSuccess(false);
          setWarningMessage(error.message);
          setWarning(true);
        }
      });
    if (mounted.current) {
      setLoading(false);
    }
  };

  const closeSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    if (mounted.current) {
      setSuccess(false);
    }
  };

  const closeWarning = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    if (mounted.current) {
      setWarning(false);
      setWarningMessage('');
    }
  };

  return (
    <div>
      <Dialog
        className={classes.dialog}
        open={props.open}
        onClose={() => {
          if (mounted.current) {
            setWarning(false);
            props.close();
          }
        }}
        aria-labelledby='form-dialog-title'>
        <DialogTitle id='form-dialog-title'>Reset password</DialogTitle>
        <DialogContent>
          <DialogContentText>
            A link to reset your password will be sent to your email address.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setWarning(false);
              props.close();
            }}
            color='primary'>
            Cancel
          </Button>
          <Button variant='outlined' onClick={submit} color='primary'>
            Send
            {loading && (
              <CircularProgress size={30} className={classes.progess} />
            )}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={success} autoHideDuration={10000} onClose={closeSuccess}>
        <Alert
          elevation={6}
          className={classes.alert}
          onClose={closeSuccess}
          severity='success'
          variant='filled'>
          Password reset email sent
        </Alert>
      </Snackbar>
      <Snackbar open={warning} autoHideDuration={10000} onClose={closeWarning}>
        <Alert
          elevation={6}
          className={classes.alert}
          onClose={closeWarning}
          severity='warning'
          variant='filled'>
          {warningMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
