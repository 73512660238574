import Button from '@mui/material/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import firebase from '../firebase.js';
import { NavLink } from 'react-router-dom';
import AccountBoxSharpIcon from '@material-ui/icons/AccountBoxSharp';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  popper: {
    left: 0,
    width: 8 + 'rem',
    backgroundColor: theme.palette.primary.main
    // width: 100 + '%',
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  buttonAccount: {
    whiteSpace: 'pre',
    backgroundColor: theme.palette.primary.main
  },
}));

export default function AccountMenuList(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const hide = false;//useMediaQuery('(min-width: 700px)');

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div className={classes.root}>
      <div>
        <Button
          className={classes.buttonAccount}
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup='true'
          onClick={handleToggle}
          variant='contained'
          disableElevation={true}
          style={{backgroundColor: '#1e3c9e'}}>
          <AccountBoxSharpIcon fontSize='large' />{' '}
          {hide ? ' ' + props.email : ''}
        </Button>

        <Popper
          className={classes.popper}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}>
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id='menu-list-grow'
                    onKeyDown={handleListKeyDown}>
                    <NavLink
                      to='/account'
                      style={{
                        color: 'inherit',
                        textDecoration: 'inherit',
                        display: 'inherit',
                      }}>
                      <MenuItem onClick={handleClose}>My Account</MenuItem>
                    </NavLink>
                    <NavLink to='/login'
                       style={{
                         color: 'inherit',
                         textDecoration: 'inherit',
                         display: 'inherit',
                       }}>
                    <MenuItem
                      onClick={(event) => {
                        handleClose(event);
                        firebase
                          .auth()
                          .signOut()
                          .then(() => {
                            // Sign-out successful.
                          })
                          .catch((error) => {
                            // An error happened.
                          });
                        // window.location.reload();
                      }}>
                      Logout
                    </MenuItem>
                    </NavLink>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}
