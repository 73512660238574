import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import { NavLink } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/styles/withStyles';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import fire from '../firebase.js';
import Paper from '@material-ui/core/Paper';
import WelcomePage from './Welcome/WelcomePage.js';
import { AuthContext } from '../Auth.js';
import React, { Component } from 'react';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import { Navigate } from "react-router-dom";

const styles = (theme) => ({
  root: {
    minHeight: 100 + '%',
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    minWidth: 290 + 'px',
  },
  container: {
    padding: 8,
    [theme.breakpoints.down('xs')]: {
      padding: 15,
    },
    minHeight: 100 + '%',
    maxWidth: 455 + 'px',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    padding: 20,
    borderRadius: 0,
    width: 100 + '%',
  },
  paperBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(1, 0, 2),
  },
  customError: {
    color: 'red',
    fontSize: '0.8rem',
    marginTop: 10,
  },
  progess: {
    position: 'absolute',
  },
  signUp: {
    textAlign: 'right',
  },
  navLink: {
    color: theme.palette.primary.main,
    fontSize: 1 + 'rem',
  },
});

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      errors: [],
      loading: false,
      showPassword: false,
    };

    this.mounted = false;
  }

  static contextType = AuthContext;

  componentDidMount() {
    this.mounted = true;
    const value = this.context;
    if (value.currentUser) {
      return (<Navigate to="/" />);
    }
  }

  componentDidUpdate(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.UI.errors,
      });
    }

    const value = this.context;
    if (value.currentUser) {
      return (<Navigate to="/" />);
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handleChange = (event) => {
    if (this.mounted) {
      this.setState({
        [event.target.name]: event.target.value,
      });
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ loading: true });
    fire
      .auth()
      .signInWithEmailAndPassword(this.state.email, this.state.password)
      .then(() => {})
      .catch((error) => {
        if (this.mounted) {
          this.setState({
            errors: error.message,
          });
          this.setState({ loading: false });
        }
      });
  };

  render() {
    const { classes } = this.props;
    const { errors, loading, showPassword } = this.state;

    const handleClickShowPassword = (event) => {
      if (this.mounted) this.setState({ showPassword: !showPassword });
    };

    if (this.context.currentUser) {
        return (<Navigate to="/" />);
    }

    return (
      <div className={classes.root}>
        <Container
          component='main'
          maxWidth='xs'
          className={classes.container}
          disableGutters={true}>
          <CssBaseline />
          <WelcomePage />
          <Paper elevation={3} className={classes.paper}>
            <div className={classes.paperBox}>
              <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component='h1' variant='h5'>
                Login
              </Typography>
              <form className={classes.form} noValidate>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      size='small'
                      variant='outlined'
                      required
                      fullWidth
                      id='email'
                      label='Email address'
                      name='email'
                      autoComplete='email'
                      helperText={errors.email}
                      error={errors.email ? true : false}
                      onChange={this.handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      size='small'
                      variant='outlined'
                      required
                      fullWidth
                      name='password'
                      label='Password'
                      type={showPassword ? 'text' : 'password'}
                      id='passwordRepeat'
                      autoComplete='current-password'
                      helperText={errors.password}
                      error={errors.password ? true : false}
                      onChange={this.handleChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <IconButton
                              aria-label='toggle password visibility'
                              onClick={handleClickShowPassword}
                              onMouseDown={(event) => event.preventDefault}>
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
                {errors && (
                  <Typography variant='body2' className={classes.customError}>
                    {errors}
                  </Typography>
                )}
                <br/>
                <Button
                  type='submit'
                  id='sign-in-btn'
                  fullWidth
                  variant='contained'
                  color='primary'
                  className={classes.submit}
                  onClick={this.handleSubmit}
                  disabled={
                    loading || !this.state.email || !this.state.password
                  }>
                  Sign in
                  {loading && (
                    <CircularProgress size={30} className={classes.progess} />
                  )}
                </Button>
                <Grid container alignItems='center'>
                  <Grid item xs={6}>
                    <NavLink
                      to='/forgot-password'
                      variant='body2'
                      className={classes.navLink}>
                      {'Forgot password?'}
                    </NavLink>
                  </Grid>
                  <Grid item xs={6} className={classes.signUp}>
                    <NavLink
                      to='/signup'
                      variant='body2'
                      className={classes.navLink}>
                      {'Sign up instead'}
                    </NavLink>
                  </Grid>
                </Grid>
                {!errors && (
                  <Typography variant='body2' className={classes.customError}>
                    Error: {this.state.errors}
                  </Typography>
                )}
              </form>
            </div>
          </Paper>
        </Container>
      </div>
    );
  }
}

export default withStyles(styles)(Login);
