import React, { Component } from 'react';

import { AuthContext } from '../../Auth.js';
import firebase from "../../firebase";

import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import axios from 'axios';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

function formatDate(str) {
  return str.substr(0, 10);
}

function capitalize(str) {
  return str.split(' ').map(s => {
    return s.charAt(0).toUpperCase() + s.substr(1);
  }).join(' ');
}

function createData(date, truck, desc, id) {
    return {"date": date, "truck": truck, "desc": desc, "id": id};
}

class UserTableRow extends React.Component {
    state = { expanded: false }
    constructor(props) {
        super(props);

        this.cancel = this.cancel.bind(this);
        this.approve = this.approve.bind(this);
    }

    async cancel() {
        const { user } = this.props;
        const db = firebase.firestore();
        await db.collection("truckLogs").doc(user.id).delete();
        await this.props.refreshData();
    }

    async approve() {
        const { user } = this.props;
        const db = firebase.firestore();
        await db.collection("truckLogs").doc(user.id).delete();

        await db.collection("truckLogsCompleted").add(user);
        await this.props.refreshData();
    }

  render() {
    const { user } = this.props;
    return [
    <div style={{border: '5px solid black', padding: '10px'}}>
        <div style={{ position: 'relative' }}>
            <Button onClick={this.cancel}
                style={{color:"#b33f3d",
                marginLeft: "86%",
                marginTop: "-4%",
                display: "block",
                position: 'absolute'
                }}>
                    <CancelIcon/>
            </Button>
            <Button onClick={this.approve}
                style={{color:"#2d9627",
                marginLeft: "86%",
                marginTop: "5%",
                display: "block",
                position: 'absolute'
                }}>
                <CheckCircleIcon/>
            </Button>
        </div>
      <div>
          <h4 style={{padding: '0px 0px 0px 10px'}}>
            {user.date}: {user.truck}
          </h4>
      </div>
      <div style={{border: '2px solid black', padding: '10px 10px 10px 10px'}}>
        {user.desc}
      </div>
    </div>
    ];
  }
}

class UserTableRow2 extends React.Component {
    constructor(props) {
        super(props);
        this.cancel = this.cancel.bind(this);
    }

    async cancel() {
        const { user } = this.props;
        const db = firebase.firestore();
        await db.collection("truckLogsCompleted").doc(user.id).delete();
        await this.props.refreshData();
    }
    render() {
        const { user } = this.props;
        return [
            <div style={{border: '5px solid black', padding: '10px'}}>
                <div style={{ position: 'relative' }}>
                    <Button onClick={this.cancel}
                        style={{color:"#b33f3d",
                        marginLeft: "86%",
                        marginTop: "-4%",
                        position: 'absolute'
                        }}>
                            <CancelIcon/>
                    </Button>
                </div>
              <div>
                  <h4 style={{padding: '0px 0px 0px 10px'}}>
                    {user.date}: {user.truck}
                  </h4>
              </div>
              <div style={{border: '2px solid black', padding: '10px 10px 10px 10px'}}>
                {user.desc}
              </div>
            </div>
        ];
    }
}

class MaintenanceHistory extends Component {
    constructor(props) {
        super(props);

        this.refreshData = this.refreshData.bind(this);
    }
    static contextType = AuthContext;

    state = { truckLogs: null, truckLogsCompleted: null }
	async componentWillMount() {
	    const db = firebase.firestore();
	    const ret = await db.collection('truckLogs').get();
	    let hardData = [];
	    ret.forEach(doc => {
          const temp = doc.data();
          temp.id = doc.id;
          hardData.push(createData(temp.date, temp.truck, temp.desc, temp.id));
        });
        hardData.sort(function(a,b){
            const diff = new Date(b.date) - new Date(a.date);
            if (diff > 0) {return 1;}
            if (diff < 0) {return -1;}
            if (b.truck > a.truck) {return -1;}
            if (b.truck < a.truck) {return 1;}
            return 0;
        });

        let ret2 = await db.collection('truckLogsCompleted').get();
        let hardData2 = [];
        ret2.forEach(doc => {
          const temp = doc.data();
          temp.id = doc.id;
          hardData2.push(createData(temp.date, temp.truck, temp.desc, temp.id));
        });
        hardData2.sort(function(a,b){
            const diff = new Date(b.date) - new Date(a.date);
            if (diff > 0) {return 1;}
            if (diff < 0) {return -1;}
            if (b.truck > a.truck) {return -1;}
            if (b.truck < a.truck) {return 1;}
            return 0;
        });
        this.setState({truckLogs: hardData, truckLogsCompleted: hardData2});
	};

	async refreshData() {
	    await this.componentWillMount();
	}

	render() {
        const { truckLogs, truckLogsCompleted } = this.state;
        const isLoading = truckLogs === null;
        const isLoadingCompleted = truckLogsCompleted === null;
        return (
          <main>
            <div>
                <h2> Maintenance Logs: </h2>
                {isLoading
                  ? "Loading..."
                  : truckLogs.map((log, index) =>
                      <UserTableRow key={index} user={log} refreshData={this.refreshData}/>
                    )
                }
                <h2> Completed: </h2>
                {isLoadingCompleted
                  ? "Loading..."
                  : truckLogsCompleted.map((log, index) =>
                    <UserTableRow2 key={index} user={log} refreshData={this.refreshData}/>
                  )}
            </div>
          </main>
        );
	}
}

export default MaintenanceHistory;