import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import { NavLink } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/styles/withStyles';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import fire from '../../firebase.js';
import Paper from '@material-ui/core/Paper';
import WelcomePage from '../Welcome/WelcomePage.js';
import { AuthContext } from '../../Auth.js';
import React, { Component } from 'react';

const styles = (theme) => ({
  root: {
    minHeight: 100 + '%',
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    minWidth: 290 + 'px',
  },
  container: {
    padding: 8,
    [theme.breakpoints.down('xs')]: {
      padding: 15,
    },
    minHeight: 100 + '%',
    maxWidth: 455 + 'px',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    padding: 20,
    borderRadius: 0,
    width: 100 + '%',
  },
  paperBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(1, 0, 2),
  },
  customError: {
    color: 'red',
    fontSize: '0.8rem',
    padding: 5,
  },
  progess: {
    position: 'absolute',
  },
  signUp: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  navLink: {
    color: theme.palette.primary.main,
    fontSize: 1 + 'rem',
  },
  snackbar: {
    // top: 0,
  },
  alert: {
    // border: '2px solid white',
  },
});

class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      email: '',
      errors: [],
      loading: false,
    };

    this.mounted = false;
  }

  static contextType = AuthContext;

  componentDidMount() {
    this.mounted = true;
    const { currentUser } = this.context;
    if (currentUser) {
      this.props.history.push('/');
    }
  }

  componentDidUpdate(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.UI.errors,
      });
    }

    const { currentUser } = this.context;
    if (currentUser) {
      this.props.history.push('/');
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handleChange = (event) => {
    if (this.mounted) {
      this.setState({
        [event.target.name]: event.target.value,
      });
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ loading: true });
    fire
      .auth()
      .sendPasswordResetEmail(this.state.email)
      .then((response) => {
        if (this.mounted) {
          this.setState({ open: true });
          this.setState({ errors: [] });
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        if (this.mounted) {
          this.setState({
            errors: error.message,
          });
          this.setState({ loading: false });
        }
      });
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    if (this.mounted) this.setState({ open: false });
  };

  render() {
    const { classes } = this.props;
    const { errors, loading, open } = this.state;

    return (
      <div className={classes.root}>
        <Container component='main' maxWidth='xs' className={classes.container}>
          <CssBaseline />
          <WelcomePage />
          <Paper elevation={3} className={classes.paper}>
            <div className={classes.paperBox}>
              <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component='h1' variant='h5'>
                Reset password
              </Typography>
              <form className={classes.form} noValidate>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      size='small'
                      variant='outlined'
                      required
                      fullWidth
                      id='email'
                      label='Email address'
                      name='email'
                      autoComplete='email'
                      helperText={errors.email}
                      error={errors.email ? true : false}
                      onChange={this.handleChange}
                    />
                  </Grid>
                </Grid>
                {errors && (
                  <Typography variant='body2' className={classes.customError}>
                    {errors}
                  </Typography>
                )}
                <Button
                  type='submit'
                  fullWidth
                  variant='contained'
                  color='primary'
                  className={classes.submit}
                  onClick={this.handleSubmit}
                  disabled={loading || !this.state.email}>
                  Send password reset email
                  {loading && (
                    <CircularProgress size={30} className={classes.progess} />
                  )}
                </Button>
                <Grid container>
                  <Grid item xs={6}>
                    <NavLink
                      to='/login'
                      variant='body2'
                      className={classes.navLink}>
                      {'Back to sign in'}
                    </NavLink>
                  </Grid>
                </Grid>
              </form>
            </div>
          </Paper>
        </Container>
        <Snackbar
          className={classes.snackbar}
          open={open}
          autoHideDuration={6000}
          onClose={this.handleClose}>
          <Alert
            elevation={6}
            className={classes.alert}
            onClose={this.handleClose}
            severity='success'
            variant='filled'>
            Password reset email sent
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

export default withStyles(styles)(ForgotPassword);
