import React, { Component } from 'react';
import firebase from "../../firebase";

import withStyles from '@material-ui/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import TimePicker from '@material-ui/lab/TimePicker';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/core/Autocomplete';
import { Typography } from '@material-ui/core';
import { getTrucks } from '../../components/forms/formsService';

import { AuthContext } from '../../Auth.js';
import { roundTimeQuarterHour } from '../../components/forms/formsService.js';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

const styles = (theme) => ({
	content: {
	},
	uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		left: '50%',
		top: '35%'
	},
	formControl: {
        minWidth: 10000
      },
    centering: {
          display: 'flex',
          justifyContent: 'center'
    },
    autoCompleteText: {
        fontSize: "3px"
    }
});

class Maintenance extends Component {
	constructor(props) {
		super(props);
        const now = dayjs();
        this.state = {truc: "",
                        trucks: [],
                        desc: ""};
        this.handleSubmit = this.handleSubmit.bind(this);
	}

	async componentDidMount() {
        const db = firebase.firestore();
        const truckPreference = await db.collection('users').doc(this.context.currentUser.email).get()
            .then((querySnapshot) => {
                return querySnapshot.data().truck;
            });
        this.setState({
            truc: truckPreference
        })
        let trucks = await getTrucks(db, truckPreference);
        this.setState({
            trucks: trucks,
            uiLoading: false
        });
    }

	static contextType = AuthContext;

	async handleSubmit(event) {
        event.preventDefault();
        const db = firebase.firestore();
        var today = dayjs().format('MM/DD/YYYY');

        await db.collection('truckLogs').add({
            date: today,
            truck: this.state.truc,
            desc: this.state.desc,
        });
        alert("Added!");
    }

    handleChange = (event, newEvent) => {
        if (event.target.id === 'desc') {
            this.setState({
                [event.target.id]: event.target.value
            });
        } else {
            this.setState({
                [event.target.id.substring(0,4)]: newEvent
            });
        }
	};

	render() {
		const { classes } = this.props;

		if (this.state.uiLoading === true) {
			return (
				<main className={classes.content}>
					{this.state.uiLoading && <CircularProgress size={150} className={classes.uiProgess} />}
				</main>
			);
		} else {
			return (
				<main className={classes.content}>
				<form onSubmit={this.handleSubmit}>
					<TableContainer component={Paper}>
                          <Table aria-label="customized table">
                            <TableHead>
                              <TableRow>
                                  <TableCell colSpan={4} style={{fontSize:12, backgroundColor: 'black', color: 'white'}}>
                                      Colossians 3:23-24: "Whatever you do, work at it
                                      with all your heart, as working for the Lord, not for
                                      human masters, since you know that you will receive an inheritance
                                      from the Lord as a reward. It is the Lord Christ you are serving."
                                  </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                  <TableCell colSpan={4}>
                                        <FormControl className={classes.formControl} style={{width: '100%'}}>
                                            <Autocomplete
                                            freeSolo
                                            id="truc"
                                            disableClearable
                                            options={this.state.trucks.map((option) => option.title)}
                                            value={this.state.truc}
                                            onChange={this.handleChange}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                label="Truck ID"
                                                margin="normal"
                                                variant="outlined"
                                                onChange={this.handleChange}
                                                InputProps={{ ...params.InputProps, type: 'search', style: {fontSize: 9}}}
                                              />
                                            )}
                                          />
                                        </FormControl>
                                  </TableCell>
                                  </TableRow>
                                  <TableRow>
                                      <TableCell colSpan={4}>
                                          <TextField
                                            id='desc'
                                            style={{width: '100%'}}
                                            placeholder="Description of Maintenance Requirement"
                                            multiline
                                            rows={2}
                                            rowsMax={4}
                                            onChange={this.handleChange}
                                            value={this.state.desc}
                                          />
                                      </TableCell>
                                  </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <br/>
                        <div className={classes.centering}>
                        <Button variant="contained"
                            onClick={this.handleSubmit}
                            style={{width:'250px', height:'50px'}}
                            disabled={!this.state.truc ||
                                !this.state.desc}>
                                    Submit
                        </Button>
                        </div>
                  </form>
				</main>
			);
		}
	}
}

export default withStyles(styles)(Maintenance);