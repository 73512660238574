import firebase from 'firebase/app';
import 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyBekdu9fuxwwMrzH8gd-Mzr2-GBkP6_fEs',
  authDomain: 'maxdeliverytracking.firebaseapp.com',
  databaseURL: 'https://maxdeliverytracking-default-rtdb.firebaseio.com/',
  projectId: 'maxdeliverytracking',
  storageBucket: 'gs://maxdeliverytracking.appspot.com',
  messagingSenderId: '308450286294',
  appId: '1:308450286294:web:9650464c52d8125d2f0777',
};

firebase.initializeApp(firebaseConfig);

export default firebase;