import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    color: 'white',
    width: '100%',
  },
  typography: {
    fontSize: 2.3 + 'rem',
  },
  container: {
    padding: 10,
    margin: 0,
    backgroundColor: theme.palette.primary.main,
    borderRadius: '0',
  },
  divider: {
    backgroundColor: 'white',
  },
  center: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

export default function WelcomePage() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container className={classes.container}>
        <img src="https://i.imgur.com/s8ekBKB.png" alt="PROSPECT LLC" width="95" height="95" className={classes.center}/>
        <Typography variant='h3' className={classes.typography}>
          <Box textAlign='center'>Prospect Delivery</Box>
        </Typography>
        <Divider className={classes.divider} />
        <Typography variant='subtitle1'>
          <Box textAlign='center'>Driver's Logging Application</Box>
        </Typography>
      </Container>
    </div>
  );
}
