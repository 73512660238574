import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import {
  createTheme,
} from '@material-ui/core/styles';
import {makeStyles, ThemeProvider} from '@material-ui/styles';
import Home from './pages/Home';
import Login from './pages/login';
import Signup from './pages/signup';
import Navigation from './components/Navigation';
import { AuthProvider } from './Auth.js';
import Container from '@material-ui/core/Container';
import Account from './pages/Account/Account.js';
import Logs from './pages/Logs/Logs.js';
import EndOfDay from './pages/endOfDay.js';
import EndScreen from './pages/endScreen/endScreen.js';
import Maintenance from './pages/Maintenance/Maintenance.js';
import MaintenanceHistory from './pages/Maintenance/MaintenanceHistory.js';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword.js';
import PageNotFound from './pages/PageNotFound/PageNotFound.js';
import TimeCard from './pages/TimeCard/Timecard.js'
import Times from './pages/TimeCard/Times.js'
import './App.css'

const theme = createTheme({
   palette: {
     primary: {
       main: '#1e3c9e',
     },
     secondary: {
       main: '#1565c0',
     },
   },
 });

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '16px 16px',
    maxWidth: 1650 + 'px',
    minWidth: 290 + 'px',
  },
}));

function App() {
    const classes = useStyles();
	return (
		<ThemeProvider theme={theme}>
		<AuthProvider>
			<Router>
                <Navigation/>
                <Container className={classes.container}>
					<Routes>
						<Route path="/" element={<Home/>} />
						<Route path="/login" element={<Login/>} />
						<Route path="/signup" element={<Signup/>} />
						<Route path='/account' element={<Account/>} />
						<Route path='/logs' element={<Logs/>} />
						<Route path='/time-card' element={<TimeCard/>} />
						<Route path='/times' element={<Times/>} />
						<Route path='/end-of-day' element={<EndOfDay/>} />
						<Route path='/end-screen' element={<EndScreen/>} />
						<Route path='/maintenance' element={<Maintenance/>} />
						<Route path='/maintenance-logs' element={<MaintenanceHistory/>} />
						<Route path='/forgot-password' element={<ForgotPassword/>} />
						<Route element={<PageNotFound/>} />
					</Routes>
                </Container>
			</Router>
		</AuthProvider>
		</ThemeProvider>
	);
}

export default App;