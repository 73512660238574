import React, { Component } from 'react';

import { slideDown, slideUp } from './anim';
import './styles_logs.css';

import { AuthContext } from '../../Auth.js';
import firebase from "../../firebase";

import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';

import axios from 'axios';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

function formatDate(str) {
  return str.substr(0, 10);
}

function capitalize(str) {
  return str.split(' ').map(s => {
    return s.charAt(0).toUpperCase() + s.substr(1);
  }).join(' ');
}

function createData(date, proj, mat, load) {
    return {"date": date, "project": proj, "material": mat, "load": load};
}

class UserTableRow extends React.Component {
  state = { expanded: false }

  toggleExpander = (e) => {
    if (e.target.type === 'checkbox') return;

    if (!this.state.expanded) {
      this.setState(
        { expanded: true },
        () => {
          if (this.refs.expanderBody) {
            slideDown(this.refs.expanderBody);
          }
        }
      );
    } else {
      slideUp(this.refs.expanderBody, {
        onComplete: () => { this.setState({ expanded: false }); }
      });
    }
  }

  render() {
    const { user } = this.props;
    return [
      <tr key="main" style={{border: '2px solid black', padding: '10px'}}>
        <td>{user.date}</td>
        <td>{user.project}</td>
        <td>{user.material}</td>
        <td>{user.load}</td>
      </tr>
    ];
  }
}

class Log extends Component {

    static contextType = AuthContext;

    state = { users: null }
	async componentWillMount() {
	    const db = firebase.firestore();
	    const ret = await db.collection('users').doc(this.context.currentUser.email).collection("loads").get();
	    let hardData = [];
	    ret.forEach(doc => {
          const temp = doc.data();
          temp.id = doc.id;
          hardData.push(createData(temp.date, temp.project, temp.material, temp.loadCount));
        });
        hardData.sort(function(a,b){
            const diff = new Date(b.date) - new Date(a.date);
            if (diff > 0) {return 1;}
            if (diff < 0) {return -1;}
            if (b.project > a.project) {return -1;}
            if (b.project < a.project) {return 1;}
            if (b.material > a.material) {return -1;}
            if (b.material < a.material) {return 1;}
            return 0;
        });
        this.setState({users: hardData});
	};

	render() {
        const { users } = this.state;
        const isLoading = users === null;
        return (
          <main>
            <div className="table-container" style={{margin: '0px 0px 0px -5px'}}>
              <div className="uk-overflow-auto">
                <table className="uk-table uk-table-hover uk-table-middle uk-table-divider">
                  <thead>
                    <tr style={{border: '2px solid black', padding: '10px'}}>
                      <th>Date</th>
                      <th>Project</th>
                      <th>Material</th>
                      <th>Load Count</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading
                      ? <tr><td colSpan={6} className="uk-text-center"><em className="uk-text-muted">Loading...</em></td></tr>
                      : users.map((user, index) =>
                          <UserTableRow key={index} user={user}/>
                        )
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </main>
        );
	}
}

export default Log;