import { Divider } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, useTheme } from '@material-ui/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import DashboardIcon from '@material-ui/icons/Dashboard';
import MenuIcon from '@material-ui/icons/Menu';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ArticleIcon from '@mui/icons-material/Article';
import HandymanIcon from '@mui/icons-material/Handyman';
import DvrIcon from '@material-ui/icons/Dvr';
import TableChartIcon from '@material-ui/icons/TableChart';
import clsx from 'clsx';
import React from 'react';
import { NavLink } from 'react-router-dom';
import AccountMenuList from './AccountMenu.js';
import { useContext } from 'react';
import { AuthContext } from '../Auth.js';

const drawerWidth = 255;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 14,
    backgroundColor: theme.palette.primary.main
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 5,
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    [theme.breakpoints.down('xs')]: {
      width: drawerWidth - 40,
    },
    height: 100 + '%',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    height: 100 + '%',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  chevronClose: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  closeButton: {
    backgroundColor: '#fdfdfd',
  },
}));

export default function Navigation() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const { currentUser } = useContext(AuthContext);
  if (!currentUser) {
    console.log("NO USER, SO NO NAVIGATION!!");
    return <></>;
  }
  const toggleDrawer = () => () => {
    setOpen(!open);
  };

  const list = (
    <div onClick={toggleDrawer()} onKeyDown={toggleDrawer()}>
      <List>
        <ListItem className={classes.chevronClose}>
          <IconButton className={classes.closeButton}>
            <ChevronLeftIcon />
            Back
          </IconButton>
        </ListItem>
        <Divider />
        <NavLink
          to='/'
          style={{
            color: 'inherit',
            textDecoration: 'inherit',
            display: 'inherit',
          }}>
          <ListItem button>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary='New Log' />
          </ListItem>
        </NavLink>
        <NavLink
          to='/logs'
          style={{
            color: 'inherit',
            textDecoration: 'inherit',
            display: 'inherit',
          }}>
          <ListItem button>
            <ListItemIcon>
              <TableChartIcon />
            </ListItemIcon>
            <ListItemText primary='My Logs' />
          </ListItem>
        </NavLink>
        <NavLink
          to='/time-card'
          style={{
            color: 'inherit',
            textDecoration: 'inherit',
            display: 'inherit',
          }}>
          <ListItem button>
            <ListItemIcon>
              <AccessTimeIcon />
            </ListItemIcon>
            <ListItemText primary='Time Card' />
          </ListItem>
        </NavLink>
        <NavLink
          to='/end-of-day'
          style={{
            color: 'inherit',
            textDecoration: 'inherit',
            display: 'inherit',
          }}>
          <ListItem button>
            <ListItemIcon>
              <DoneAllIcon />
            </ListItemIcon>
            <ListItemText primary='Sign-off' />
          </ListItem>
        </NavLink>
        <NavLink
          to='/end-screen'
          style={{
            color: 'inherit',
            textDecoration: 'inherit',
            display: 'inherit',
          }}>
          <ListItem button>
            <ListItemIcon>
              <DvrIcon />
            </ListItemIcon>
            <ListItemText primary='Sign-off Summary' />
          </ListItem>
        </NavLink>
        <NavLink
          to='/maintenance'
          style={{
            color: 'inherit',
            textDecoration: 'inherit',
            display: 'inherit',
          }}>
          <ListItem button>
            <ListItemIcon>
              <HandymanIcon />
            </ListItemIcon>
            <ListItemText primary='Maintenance Report' />
          </ListItem>
        </NavLink>
        <NavLink
          to='/maintenance-logs'
          style={{
            color: 'inherit',
            textDecoration: 'inherit',
            display: 'inherit',
          }}>
          <ListItem button>
            <ListItemIcon>
              <ArticleIcon />
            </ListItemIcon>
            <ListItemText primary='Maintenance Logs' />
          </ListItem>
        </NavLink>
      </List>
      {/* <Divider />
      <List>
        <ListSubheader inset>Forms</ListSubheader>
        <a
          href=''
          style={{
            color: 'inherit',
            textDecoration: 'inherit',
            display: 'inherit',
          }}>
          <ListItem button>
            <ListItemIcon>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary='Important Form' />
          </ListItem>
        </a>
      </List> */}
    </div>
  );

  return (
    <div id='navigation' className={classes.root}>
      <CssBaseline />
      <AppBar position='static' className={clsx(classes.appBar)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge='start'
            color='inherit'
            aria-label='menu'
            onClick={toggleDrawer()}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}>
            <MenuIcon />
          </IconButton>
          <Typography variant='h6' className={classes.title}>
            <NavLink
              to='/'
              style={{ color: 'inherit', textDecoration: 'inherit' }}>
              <img src="https://i.imgur.com/s8ekBKB.png" alt="PROSPECT LLC" height="25px" style={{marginRight:"3px", marginTop:"7px"}} />
              <img src="https://i.imgur.com/4DbvCjq.png" alt="PROSPECT LLC" width="80px"  style={{marginTop:"7px"}}/>
            </NavLink>
          </Typography>
          <AccountMenuList email={currentUser ? currentUser.email : ''} />
        </Toolbar>
      </AppBar>
      <Drawer
        variant='temporary'
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
        onClose={toggleDrawer()}>
        {list}
      </Drawer>
    </div>
  );
}
