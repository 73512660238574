import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  dialog: {
    maxWidth: 350 + 'px',
    minWidth: 290 + 'px',
    margin: 'auto',
  },
}));

export default function ChangeEmail(props) {
  const classes = useStyles();
  //   const [email, setEmail] = React.useState('');

  const submit = (event) => {
    props.close();
  };

  return (
    <div>
      <Dialog
        className={classes.dialog}
        open={props.open}
        onClose={props.close}
        aria-labelledby='form-dialog-title'>
        <DialogTitle id='form-dialog-title'>Change email</DialogTitle>
        <DialogContent>
          <DialogContentText>
            A confirmation email to change your email address will be sent to
            your current email address.
          </DialogContentText>
          <TextField
            autoFocus
            fullWidth={true}
            disabled={true}
            margin='dense'
            name='email'
            label='New email'
            type='text'
            variant='outlined'
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(event) => {
              //   setEmail(event.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={props.close} color='primary'>
            Cancel
          </Button>
          <Button
            disabled={true}
            variant='outlined'
            onClick={submit}
            color='primary'>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
