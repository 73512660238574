import React, { Component } from 'react';
import firebase from "../../firebase";

import withStyles from '@material-ui/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import TimePicker from '@material-ui/lab/TimePicker';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/core/Autocomplete';
import { NavLink } from 'react-router-dom';
import { Typography } from '@material-ui/core';

import { AuthContext } from '../../Auth.js';
import { roundTimeQuarterHour } from '../../components/forms/formsService.js';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

const styles = (theme) => ({
	content: {
	},
	uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		left: '50%',
		top: '35%'
	},
	formControl: {
        minWidth: 10000
      },
    centering: {
          display: 'flex',
          justifyContent: 'center'
    },
    autoCompleteText: {
        fontSize: "3px"
    }
});

class TimeCard extends Component {
	constructor(props) {
		super(props);
        const now = dayjs();
        this.state = {time3: now,
                        time4: now};
        this.handleSubmit = this.handleSubmit.bind(this);
	}

	static contextType = AuthContext;

	async handleSubmit(event) {
        event.preventDefault();
        const db = firebase.firestore();
        var today = dayjs().format('MM/DD/YYYY');

        const name = await db.collection('users').doc(this.context.currentUser.email).get();
        const totalTime2 = ((new Date(this.state.time4) - new Date(this.state.time3))/3600000).toFixed(2);
        if (totalTime2 < 0) {
            alert("Time entered is negative!");
            return;
        }

        if (isNaN(totalTime2)) {
            alert("Error collecting times, refresh and try again...");
            return;
        }

        await db.collection('times').add({
            date: today,
            user: name.data().firstName + ' ' + name.data().lastName,
            drivingTime: totalTime2,
        });
        alert("Added time!");
    }

    handleDateChange3 = (date) => {
        const time = roundTimeQuarterHour(date);
        this.setState({time3: time});
    }

    handleDateChange4 = (date) => {
        const time = roundTimeQuarterHour(date);
        this.setState({time4: time});
    }

	render() {
		const time3 = this.state.time3;
        const time4 = this.state.time4;
		const { classes } = this.props;

		if (this.state.uiLoading === true) {
			return (
				<main className={classes.content}>
					{this.state.uiLoading && <CircularProgress size={150} className={classes.uiProgess} />}
				</main>
			);
		} else {
			return (
				<main className={classes.content}>
				<form onSubmit={this.handleSubmit}>
					<TableContainer component={Paper}>
                          <Table aria-label="customized table">
                            <TableHead>
                              <TableRow>
                                  <TableCell colSpan={4} style={{fontSize:12, backgroundColor: '#071b26', color: 'white'}}>
                                      Colossians 3:23-24: "Whatever you do, work at it
                                      with all your heart, as working for the Lord, not for
                                      human masters, since you know that you will receive an inheritance
                                      from the Lord as a reward. It is the Lord Christ you are serving."
                                  </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                  <TableCell rowSpan={1} colSpan={2} style={{textAlign:'center',fontSize:12}}>
                                    Time In
                                    <br/>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                      <TimePicker
                                        label=""
                                        value={time3}
                                        onChange={this.handleDateChange3}
                                        renderInput={(params) => <TextField {...params} />}
                                      />
                                    </LocalizationProvider>
                                    </TableCell>
                                    <TableCell rowSpan={1} colSpan={2} style={{textAlign:'center',fontSize:12}}>
                                      Time Out
                                      <br/>
                                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <TimePicker
                                          label=""
                                          value={time4}
                                          onChange={this.handleDateChange4}
                                          renderInput={(params) => <TextField {...params} />}
                                        />
                                      </LocalizationProvider>
                                      </TableCell>
                                  </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <br/>
                        <div className={classes.centering}>
                            <Button variant="contained"
                                onClick={this.handleSubmit}
                                style={{width:'250px', height:'50px'}}
                                disabled={!this.state.time3 ||
                                    !this.state.time4}>
                                        Submit
                            </Button>
                            <br/>
                        </div>
                        <div className={classes.centering}>
                            <NavLink to='/times'>
                                <h1>Times History</h1>
                            </NavLink>
                        </div>
                  </form>
				</main>
			);
		}
	}
}

export default withStyles(styles)(TimeCard);