import React, { Component } from 'react';
import firebase from "../../firebase";

import withStyles from '@material-ui/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import TimePicker from '@material-ui/lab/TimePicker';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/core/Autocomplete';
import SignatureCanvas from 'react-signature-canvas';
import Signature from '../Signature/Signature.js'
import Box from '@material-ui/core/Box';

import { AuthContext } from '../../Auth.js';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

const styles = (theme) => ({
	content: {
		margin: 'auto',
        width: '100%',
        //border: '3px solid blue',
        padding: '0px'
	},
	uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		left: '50%',
		top: '35%'
	},
	formControl: {
        minWidth: 50
      },
    centering: {
          margin: 0,
          position: 'absolute',
          width: "100%",
    },
    autoCompleteText: {
        fontSize: "3px"
    }
});

function createData(date, proj, mat, load, id, hour, min) {
    return {"date": date, "project": proj, "material": mat, "load": load, "id": id, "hour": hour, "min": min};
}

class UserTableRow extends React.Component {
  render() {
    const { user } = this.props;
    return [
      <TableRow key="main" >
          <td >{user.material}</td>
          <td width="1px">{user.load}</td>
          <td width="1px">{Math.round((user.hour/1 + user.min/60)*100)/100} hr(s)</td>
      </TableRow>
    ];
  }
}

class endScreen extends Component {
	constructor(props) {
		super(props);
		this.myRef1 = React.createRef();
		this.myRef2 = React.createRef();
		this.handleChangeProjects = this.handleChangeProjects.bind(this);
	}

	static contextType = AuthContext;

	state = { users: null,
	          driver: "",
	          supervisor: "",
	          selectedProject: "",
            }
    async componentWillMount() {
        dayjs.extend(relativeTime);
        var today = dayjs().format('MM/DD/YYYY');
        const db = firebase.firestore();
        const ret = await db.collection('pendingLoad').where('date', '==', today).where('user', '==', this.context.currentUser.email).get();
        let hardData = {};
        const set = new Set();
        ret.forEach(doc => {
          const temp = doc.data();
          temp.id = doc.id;
          if (temp.date == today) {
            set.add(temp.project);
            if (!(temp.project in hardData)) {
                hardData[temp.project] = [];
            }
            hardData[temp.project].push(createData(temp.date, temp.project, temp.material, temp.loadCount, temp.id, temp.standbyHour, temp.standbyMin));
          }
        });
        for (const project in hardData) {
            hardData[project].sort(function(a,b){
                 if (a.material < b.material) { return -1; }
                 if (a.material > b.material) { return  1; }
                 return 0;
            });
        }
        if (this.state.project in hardData) {
            this.setState({users: hardData[this.state.project] || [], projects: [...set], allData: hardData});
        } else {
            this.setState({users: [], projects: [...set], allData: hardData});
        }

//        const res = await db.collection('users').doc(this.context.currentUser.email).collection("endOfDay").where('date', '==', today).get();
//        res.forEach(doc => {
//          const a = doc.data();
//          this.setState({driver: a.driver, supervisor: a.supervisor});
//        });
//        const res2 = await db.collection('users').doc(this.context.currentUser.email).collection("hours").where('date', '==', today).get();
//        res2.forEach(doc => {
//          const a = doc.data();
//          this.setState({truckingTime: a.truckingTime,
//                        drivingTime: a.drivingTime,
//                        standby: a.standby});
//        });
    };

    async handleChangeProjects(value, newEvent) {
        var today = dayjs().format('MM/DD/YYYY');
        const db = firebase.firestore();
        const res = await db.collection('users').doc(this.context.currentUser.email).collection("endOfDay")
            .where('date', '==', today)
            .where('project', '==', value.target.value).get();
        res.forEach(doc => {
              const a = doc.data();
              console.log(a);
              this.setState({
                  users: this.state.allData[value.target.value],
                  selectedProject: value.target.value,
                  driver: a.driver,
                  supervisor: a.supervisor,
                  truckingTimeHr: a.truckingTimeHr,
                  truckingTimeMin: a.truckingTimeMin});
        });
    }

	render() {
		dayjs.extend(relativeTime);
		var today = dayjs().format('MM/DD/YYYY');
		const { classes } = this.props;
		const users = this.state.users;
		const projects = this.state.projects;
		const isLoading = users === null;
		const selectedProject = this.state.selectedProject;
        const emptyLoads = isLoading || users.length == 0;

        return (
            <main className={classes.content}>
                <TableContainer component={Paper}>
                      <Table aria-label="customized table">
                        <TableHead>
                          <TableRow>
                            <TableCell style={{backgroundColor:'#071b26', color: 'white',}} align="center" colSpan={4}>Today's Summary</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell colSpan={4}>
                                <Box sx={{ width:'100%' }}>
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Project</InputLabel>
                                    <Select
                                      labelId="demo-simple-select-"
                                      id="demo-simple-select"
                                      label="Projects"
                                      value={selectedProject}
                                      onChange={this.handleChangeProjects}
                                    >
                                      {isLoading
                                       ? <tr><td colSpan={4} className="uk-text-center"><em className="uk-text-muted">Loading...</em></td></tr>
                                       : projects.map((project, index) => <MenuItem value={project}>{project}</MenuItem>)}
                                    </Select>
                                  </FormControl>
                                </Box>
                            </TableCell>
                          </TableRow>
                          {isLoading || emptyLoads
                             ? <div></div>
                             : <><TableRow>
                                 <TableCell rowSpan={2} colSpan={4} style={{textAlign:'center',fontSize:18}}>
                                     List of loads completed today:
                                 </TableCell>
                               </TableRow>

                               <TableRow>
                               </TableRow>
                               <TableRow>
                               </TableRow>
                               <tr>
                                 <th >Material</th>
                                 <th >Count</th>
                                 <th >Standby</th>
                                 <th style={{width:'1px'}}/>
                               </tr></>}
                        {isLoading
                          ? <tr><td colSpan={4} className="uk-text-center"><em className="uk-text-muted">Loading...</em></td></tr>
                          : users.map((user, index) =>
                              <UserTableRow key={index} user={user} handler={this.handler}/>
                            )
                        }
                            <TableRow>
                                <TableCell colSpan={4} align="center" style={{fontSize:12}}>
                                    Trucking Time: {this.state.truckingTimeHr ? this.state.truckingTimeHr : 0} Hour(s) {" "}
                                    {this.state.truckingTimeMin ? this.state.truckingTimeMin : 0} Min
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={4} align="center">
                                Driver Signature <br/>
                                <img
                                  src={this.state.driver}
                                  alt="Driver"
                                  style={{
                                    display: "block",
                                    margin: "0 auto",
                                    border: "1px solid black",
                                    width: "150px"
                                  }}
                                />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={4} align="center">
                                Approver Signature <br/>
                                <img
                                  src={this.state.supervisor}
                                  alt="Supervisor"
                                  style={{
                                    display: "block",
                                    margin: "0 auto",
                                    border: "1px solid black",
                                    width: "150px"
                                  }}
                                />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
            </main>
        );
	}
}

export default withStyles(styles)(endScreen);