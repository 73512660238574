import React, { Component } from 'react';

import { AuthContext } from '../../Auth.js';
import firebase from "../../firebase";

import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';

import axios from 'axios';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { NavLink } from 'react-router-dom';

function createData(date, drivingTime) {
    return {"date": date, "drivingTime": drivingTime};
}

class UserTableRow extends React.Component {
  state = { expanded: false }

  render() {
    const { user } = this.props;
    return [
      <tr key="main" style={{border: '2px solid black', padding: '10px'}}>
        <td>{user.date}</td>
        <td>{user.drivingTime}</td>
      </tr>
    ];
  }
}

class Times extends Component {

    static contextType = AuthContext;

    state = { users: null }
	async componentWillMount() {
	    const db = firebase.firestore();
	    const name = await db.collection('users').doc(this.context.currentUser.email).get();
	    const ret = await db.collection('times').where('user', '==', name.data().firstName + ' ' + name.data().lastName).get();
	    let hardData = [];
	    ret.forEach(doc => {
          const temp = doc.data();
          console.log(temp);
          temp.id = doc.id;
          hardData.push(createData(temp.date, temp.drivingTime));
        });
        hardData.sort(function(a,b){
            const diff = new Date(b.date) - new Date(a.date);
            if (diff > 0) {return 1;}
            if (diff < 0) {return -1;}
            return 0;
        });
        this.setState({users: hardData});
	};

	render() {
        const { users } = this.state;
        const isLoading = users === null;
        return (
          <main>
            <div>
                <NavLink to='/time-card'>
                    <h1>Back</h1>
                </NavLink>
            </div>
            <div className="table-container" style={{margin: '0px 0px 0px -5px'}}>
              <div className="uk-overflow-auto">
                <table className="uk-table uk-table-hover uk-table-middle uk-table-divider">
                  <thead>
                    <tr style={{border: '2px solid black', padding: '10px'}}>
                      <th>Date</th>
                      <th>Driving Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading
                      ? <tr><td colSpan={6} className="uk-text-center"><em className="uk-text-muted">Loading...</em></td></tr>
                      : users.map((user, index) =>
                          <UserTableRow key={index} user={user}/>
                        )
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </main>
        );
	}
}

export default Times;