import { AuthContext } from '../../Auth.js';
import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ErrorIcon from '@material-ui/icons/Error';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    textAlign: 'center',
    // width: 100 + '%',
    // height: 100 + '%',
    maxWidth: 900 + 'px',
    width: 100 + '%',
  },
  spacing: {
    padding: '8px 0 8px 0',
    display: 'flex',
    justifyContent: 'center',
  },
  divider: {
    color: 'inherit',
    height: 1 + 'px',
    width: 100 + '%',
  },
}));

export default function PageNotFound(props) {
  const classes = useStyles();
  const { currentUser } = useContext(AuthContext);
  React.useEffect(() => {
    if (!currentUser) {
      props.history.push('/login');
      return;
    }
  });

  return (
    <div className={classes.spacing}>
      <Paper className={classes.root}>
        <Typography variant='body1' gutterBottom>
          <ErrorIcon style={{ fontSize: 5 + 'rem' }} />
        </Typography>
        <Typography variant='h3' component='h1' gutterBottom>
          Page not found.
        </Typography>
        <div>
          <Divider className={classes.divider} />
        </div>
        <Typography variant='body1' gutterBottom>
          The page you were looking for doesn't exist.
        </Typography>
      </Paper>
    </div>
  );
}
