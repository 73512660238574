import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/styles';
import fire from '../../firebase.js';
import firebase from 'firebase/app';
import CircularProgress from '@material-ui/core/CircularProgress';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => ({
  dialog: {
    maxWidth: 350 + 'px',
    minWidth: 290 + 'px',
    margin: 'auto',
  },
  alert: {
    minWidth: 290 + 'px',
  },
  navLink: {
    color: theme.palette.primary.main,
    fontSize: 1 + 'rem',
  },
  buttonLink: {
    background: 'none',
    outline: 'none',
    border: 'none',
    color: theme.palette.primary.main,
    fontSize: 1 + 'rem',
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  progess: {
    position: 'absolute',
  },
}));

export default function ChangePassword(props) {
  const classes = useStyles();
  const [password, setPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [success, setSuccess] = React.useState(false);
  const [warning, setWarning] = React.useState(false);
  const [warningMessage, setWarningMessage] = React.useState('');
  const [oldPasswordError, setOldPasswordError] = React.useState(false);
  const [newPasswordError, setNewPasswordError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showNewPassword, setShowNewPassword] = React.useState(false);

  let mounted = React.useRef(false);
  React.useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  const submit = async (event) => {
    if (mounted.current) {
      setLoading(true);
      setSuccess(false);
      setWarning(false);
      setOldPasswordError(false);
      setNewPasswordError(false);
    }

    const user = fire.auth().currentUser;
    const credential = firebase.auth.EmailAuthProvider.credential(
      props.email,
      password
    );
    await user
      .reauthenticateWithCredential(credential)
      .then(() => {
        user
          .updatePassword(newPassword)
          .then(() => {
            if (mounted.current) {
              setSuccess(true);
              setWarning(false);
              setWarningMessage('');
              props.close();
            }
          })
          .catch((error) => {
            if (mounted.current) {
              setSuccess(false);
              setWarningMessage(error.message);
              setWarning(true);
              setNewPasswordError(true);
            }
          });
      })
      .catch((error) => {
        if (mounted.current) {
          setSuccess(false);
          setWarningMessage('Your current password is incorrect.');
          setWarning(true);
          setOldPasswordError(true);
        }
      });
    if (mounted.current) {
      setLoading(false);
    }
  };

  const closeSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    if (mounted.current) {
      setSuccess(false);
    }
  };

  const closeWarning = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    if (mounted.current) {
      setWarning(false);
      setWarningMessage('');
    }
  };

  const closeDialog = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    if (mounted.current) {
      setNewPasswordError(false);
      setOldPasswordError(false);
      setWarning(false);
      props.close();
    }
  };

  const handleClickShowPassword = (event) => {
    if (mounted.current) {
      setShowPassword(!showPassword);
    }
  };
  const handleClickShowNewPassword = (event) => {
    if (mounted.current) {
      setShowNewPassword(!showNewPassword);
    }
  };

  return (
    <div>
      <Dialog
        className={classes.dialog}
        open={props.open}
        onClose={() => {
          closeDialog();
        }}
        aria-labelledby='form-dialog-title'>
        <DialogTitle id='form-dialog-title'>Change password</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            error={oldPasswordError}
            fullWidth={true}
            margin='dense'
            name='password'
            label='Current password'
            type={showPassword ? 'text' : 'password'}
            variant='outlined'
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}
                    onMouseDown={(event) => event.preventDefault}>
                    {showPassword ? (
                      <Visibility fontSize='small' />
                    ) : (
                      <VisibilityOff fontSize='small' />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={(event) => setPassword(event.target.value)}
          />
          <TextField
            fullWidth={true}
            error={newPasswordError}
            margin='dense'
            name='newPassword'
            label='New password'
            type={showNewPassword ? 'text' : 'password'}
            variant='outlined'
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowNewPassword}
                    onMouseDown={(event) => event.preventDefault}>
                    {showNewPassword ? (
                      <Visibility fontSize='small' />
                    ) : (
                      <VisibilityOff fontSize='small' />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={(event) => setNewPassword(event.target.value)}
          />
          <button
            onClick={() => {
              props.openForgotPassword();
              closeDialog();
            }}
            className={classes.buttonLink}>
            Forgot password?
          </button>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color='primary'>
            Cancel
          </Button>
          <Button variant='outlined' onClick={submit} color='primary'>
            Submit
            {loading && (
              <CircularProgress size={30} className={classes.progess} />
            )}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={success} autoHideDuration={10000} onClose={closeSuccess}>
        <Alert
          elevation={6}
          className={classes.alert}
          onClose={closeSuccess}
          severity='success'
          variant='filled'>
          Password reset
        </Alert>
      </Snackbar>
      <Snackbar open={warning} autoHideDuration={10000} onClose={closeWarning}>
        <Alert
          elevation={6}
          className={classes.alert}
          onClose={closeWarning}
          severity='warning'
          variant='filled'>
          {warningMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
