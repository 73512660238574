export function getTrucks(db, truckPreference) {
    var truckRef = db.collection("trucks");
    let truckData = [];
    let count = 1;
    truckRef.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            truckData.push({
                title: doc.data().id,
                value: count
            });
            count++;
        });
        truckData.sort(function(a,b){
            if (a.title === truckPreference) { return -1; }
            if (b.title === truckPreference) { return 1; }
            if (a.title < b.title) { return -1; }
            if (a.title > b.title) { return  1; }
            return 0;
        });
    });
    return truckData;
}

export function getAddresses(db, addressPreference) {
    let truckRef = db.collection("customers");
    let truckData = [];
    let count = 1;
    truckRef.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            truckData.push({
                title: doc.data().address,
                value: count,
                customer: doc.data().customer,
                rate: doc.data().rate
            });
            count++;
        });
        truckData.sort(function(a,b){
            if (a.title === addressPreference) { return -1; }
            if (b.title === addressPreference) { return 1; }
            if (a.title < b.title) { return -1; }
            if (a.title > b.title) { return  1; }
            return 0;
        });
    });
    return truckData;
}

export function getMaterials(db) {
    var truckRef = db.collection("materials");
    let truckData = [];
    let count = 1;
    truckRef.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            truckData.push(doc.data().material);
            count++;
        });
        truckData.sort();
    });
    return truckData;
}

export function getUsers(db) {
    let truckRef = db.collection("users");
    let truckData = [];
    let count = 1;
    truckRef.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            truckData.push(
                {
                    name: doc.data().firstName + ' ' + doc.data().lastName,
                    email: doc.data().email
                });
            count++;
        });
        truckData.sort(function(a,b){
            if (a.email === "a@a.com") {a = 'zzzzzzzzzzz';}
            else if (b.email === "a@a.com") {b = 'zzzzzzzzzzz';}
            else {
                a = a.name.toLowerCase();
                b = b.name.toLowerCase();
            }
            if (a < b) { return -1; }
            if (a > b) { return  1; }
            return a > b;
        });
    });
    return truckData;
}
export function roundTimeQuarterHour(time) {
    var timeToReturn = new Date(time);

    timeToReturn.setMilliseconds(Math.round(timeToReturn.getMilliseconds() / 1000) * 1000);
    timeToReturn.setSeconds(Math.round(timeToReturn.getSeconds() / 60) * 60);
    timeToReturn.setMinutes(Math.round(timeToReturn.getMinutes() / 5) * 5);
    return timeToReturn;
}