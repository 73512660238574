import { Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/styles';
import EditIcon from '@material-ui/icons/Edit';
import firebase from 'firebase/app';
import 'firebase/firestore';
import React, { useContext } from 'react';
import { AuthContext } from '../../Auth.js';
import ChangeEmail from './ChangeEmail.js';
import ChangeName from './ChangeName.js';
import ChangePassword from './ChangePassword.js';
import PasswordReset from './PasswordReset.js';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 100 + '%',
  },
  paper: {
    maxWidth: 1000 + 'px',
    width: 100 + '%',
    margin: '8px 0 8px 0',
  },
  rowDivider: {
    margin: '0 15px',
  },
  progress: {
    position: 'absolute',
    right: 0,
    left: 0,
    bottom: 0,
    top: 0,
    width: 100 + '%',
    height: 100 + '%',
    display: 'flex',
    margin: 'auto',
  },
  listItem: {},
  list: {
    padding: 0,
  },
  listText: {},
  editButton: {
    width: 100 + '%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  gridItem: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  gridContainer: {
    justifyContent: 'flex-start',
  },
  fieldContainer: {
    padding: 16,
  },
  subheader: {
    fontSize: 1.5 + 'rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: 1 + 'rem',
    },
    color: 'black',
    position: 'static',
  },
  rowLabel: {
    color: 'gray',
    [theme.breakpoints.down('md')]: {
      fontSize: 0.8 + 'rem',
    },
  },
}));

export default function Account(props) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(true);
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [openNameChanger, setOpenNameChanger] = React.useState(false);
  const [openPasswordChanger, setOpenPasswordChanger] = React.useState(false);
  const [openEmailChanger, setOpenEmailChanger] = React.useState(false);
  const [forgotPassword, setForgotPassword] = React.useState(false);

  const { currentUser } = useContext(AuthContext);
  let mounted = React.useRef(false);
  React.useEffect(() => {
    mounted.current = true;
    if (!currentUser) {
      props.history.push('/login');
      return;
    }
    return () => {
      mounted.current = false;
    };
  });

  React.useEffect(() => {
    if (currentUser) {
      firebase
        .firestore()
        .collection('users')
        .doc(currentUser.email)
        .get()
        .then((doc) => {
          if (doc.exists) {
            if (mounted.current) {
              setFirstName(doc.data().firstName);
              setLastName(doc.data().lastName);
              setEmail(doc.data().email);
            }
          } else {
            console.log("User doesn't exist...");
            console.log(currentUser.email)
          }
        })
        .catch((error) => {});
      setTimeout(() => {
        if (mounted.current) setLoading(false);
      }, 1000);
    }
  }, [currentUser]);

  if (loading) {
    return <CircularProgress size={120} className={classes.progress} />;
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <List
          className={classes.list}
          subheader={
            <ListSubheader className={classes.subheader}>
              Basic Information
            </ListSubheader>
          }>
          <Divider />
          <ListItem
            button
            onClick={() => {
              setOpenNameChanger(true);
            }}>
            <Grid container alignItems='center'>
              <Grid item container alignItems='center' xs={10} sm={6} md={6}>
                <Grid item xs={12} sm={4}>
                  <Typography className={classes.rowLabel}>NAME</Typography>
                </Grid>
                <Grid item sm={8}>
                  <Typography className={classes.rowData}>
                    {firstName + ' ' + lastName}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={2} sm={6} md={6} className={classes.editButton}>
                <IconButton size='small' variant='contained'>
                  <EditIcon />
                </IconButton>
              </Grid>
            </Grid>
          </ListItem>
          <Divider className={classes.rowDivider} />
          <ListItem
            button
            onClick={() => {
              setOpenPasswordChanger(true);
            }}>
            <Grid container alignItems='center'>
              <Grid item container alignItems='center' xs={10} sm={6} md={6}>
                <Grid item xs={12} sm={4}>
                  <Typography className={classes.rowLabel}>PASSWORD</Typography>
                </Grid>
                <Grid item sm={8}>
                  <Typography className={classes.rowData}>••••••••</Typography>
                </Grid>
              </Grid>
              <Grid item xs={2} sm={6} md={6} className={classes.editButton}>
                <IconButton size='small' variant='contained'>
                  <EditIcon />
                </IconButton>
              </Grid>
            </Grid>
          </ListItem>
        </List>
      </Paper>
      <Paper className={classes.paper}>
        <List
          className={classes.list}
          subheader={
            <ListSubheader className={classes.subheader}>
              Contact Information
            </ListSubheader>
          }>
          <Divider />
          <ListItem
            button
            onClick={() => {
              setOpenEmailChanger(true);
            }}>
            <Grid container alignItems='center'>
              <Grid item container alignItems='center' xs={10} sm={6} md={6}>
                <Grid item xs={12} sm={4}>
                  <Typography className={classes.rowLabel}>EMAIL</Typography>
                </Grid>
                <Grid item sm={8}>
                  <Typography className={classes.rowData}>{email}</Typography>
                </Grid>
              </Grid>
              <Grid item xs={2} sm={6} md={6} className={classes.editButton}>
                <IconButton size='small' variant='contained'>
                  <EditIcon />
                </IconButton>
              </Grid>
            </Grid>
          </ListItem>
        </List>
      </Paper>
      <ChangeName
        firstName={firstName}
        lastName={lastName}
        open={openNameChanger}
        close={() => setOpenNameChanger(false)}
        currentUser={currentUser}
        updateName={(firstName, lastName) => {
          if (mounted.current) {
            setFirstName(firstName);
            setLastName(lastName);
          }
        }}
      />
      <ChangePassword
        open={openPasswordChanger}
        email={email}
        close={() => setOpenPasswordChanger(false)}
        startLoading={() => setLoading(true)}
        openForgotPassword={() => setForgotPassword(true)}
      />
      <ChangeEmail
        open={openEmailChanger}
        close={() => setOpenEmailChanger(false)}
        currentUser={currentUser}
      />
      <PasswordReset
        open={forgotPassword}
        close={() => setForgotPassword(false)}
        email={email}
      />
    </div>
  );
}
