import React, { Component } from 'react';
import firebase from "../../firebase";

import withStyles from '@material-ui/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import TimePicker from '@material-ui/lab/TimePicker';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/core/Autocomplete';
import { Typography } from '@material-ui/core';

import { AuthContext } from '../../Auth.js';
import { getTrucks, getAddresses, getMaterials, getUsers } from './formsService';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

const styles = (theme) => ({
	content: {
	},
	uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		left: '33%',
		top: '33%'
	},
	formControl: {
        minWidth: 10000
      },
    centering: {
          display: 'flex',
          justifyContent: 'center'
    },
    autoCompleteText: {
        fontSize: "3px"
    }
});

class todo extends Component {
	constructor(props) {
		super(props);

		this.state = {
			uiLoading: true,
			value: 'Fri Apr 30 2021 16:41:58 GMT-0700 (Pacific Daylight Time)',
			trucks: [],
			addresses: [],
			materials: [],
			users: [],
			user: "",
			project: "",
			proj: "",
			truc: "",
			lfro: "",
			deli: "",
			mate: "",
			load: 1,
			standbyHour: 0,
			standbyMin: 0,
		};

		this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
	}

	static contextType = AuthContext;

	handleChange = (event, newEvent) => {
	    if (event.target.id == null) {
            this.setState({
                [event.target.name.substring(0,4)]: event.target.value
            });
            console.log(event.target.name.substring(0,4));
            return;
        }
        if (event.target.id == "load-count") {
            this.setState({
                ["load"]: event.target.value
            });
            console.log("load");
            return;
        }
        if (event.target.id == "standby-hour") {
            this.setState({
                standbyHour: event.target.value
            });
            console.log(event.target.value);
        }
        if (event.target.id == "standby-min") {
            this.setState({
                standbyMin: event.target.value
            });
            console.log(event.target.value);
        }
        if (event.target.id.includes("user-setter")) {
            if (event.target.id == "user-setter") {
                this.setState({
                    ["user"]: event.target.value
                });
            } else {
                this.setState({
                    ["user"]: event.target.innerText
                });
            }
            console.log("user");
            return;
        }
	    if (event.target.id.length < 5) {
	        this.setState({
                initialLoad: true
            });
	        return;
	    }
		this.setState({
			[event.target.id.substring(0,4)]: newEvent
		});
		console.log(event.target.id);
	};

	async componentDidMount() {
	    const db = firebase.firestore();
	    const truckPreference = await db.collection('users').doc(this.context.currentUser.email).get()
	        .then((querySnapshot) => {
                return querySnapshot.data() ? querySnapshot.data().truck : "";
            });
        const addressPreference = await db.collection('users').doc(this.context.currentUser.email).get()
            .then((querySnapshot) => {
                return querySnapshot.data() ? querySnapshot.data().address : "";
            });
        this.setState({
            truc: truckPreference
        })
        let trucks = await getTrucks(db, truckPreference);
        let addresses = await getAddresses(db, addressPreference);
        let materials = await getMaterials(db);
        let users = await getUsers(db);
        this.setState({
            trucks: trucks,
            addresses: addresses,
            materials: materials,
            users: users,
            uiLoading: false
        });
	}

	async handleSubmit(event) {
        event.preventDefault();
        const db = firebase.firestore();
        var today = dayjs().format('MM/DD/YYYY');

        let customer = "";
        let rate = "";
        this.state.addresses.forEach(add => {
            if (add.title == this.state.proj) {
                if (add.customer) {
                    customer = add.customer;
                }
            }
            if (add.title == this.state.lfro) {
                if (add.rate) {
                    rate = add.rate;
                }
            }
        })
        let email = this.state.user;
        if (email) {
            this.state.users.forEach(user => {
                if (user.name == this.state.user) {
                    email = user.email;
                }
            })
        } else {
            email = this.context.currentUser.email;
        }
        const data = {
            project: this.state.proj,
            customer: customer,
            truck: this.state.truc,
            loadedFrom: this.state.lfro,
            rate: rate,
            hours: "",
            deliveredTo: this.state.deli,
            material: this.state.mate,
            loadCount: this.state.load,
            user: email,
            standbyHour: this.state.standbyHour,
            standbyMin: this.state.standbyMin,
            date: today
        }
        console.log(data.user);
        await db.collection('users').doc(this.context.currentUser.email).update({truck: this.state.truc});
        await db.collection('users').doc(this.context.currentUser.email).update({address: this.state.proj});
        await db.collection('pendingLoad').add(data).then(function(docRef) {
            data.id = docRef.id;
        });
        const res = await db.collection('users').doc(email).collection("loads").add(data);
        alert("Successful Submit!");
        await this.setState({
            proj: "",
            lfro: "",
            deli: "",
            mate: "",
            load: 1
        })
        console.log(this.state);
    }

	render() {
		dayjs.extend(relativeTime);
		var today = dayjs().format('MM/DD/YYYY');
		const { classes } = this.props;
		const isAdmin = this.context.currentUser.email == 'a@a.com' || this.context.currentUser.email == "soundsstudio@yahoo.com";

		if (this.state.uiLoading === true) {
			return (
				<main className={classes.content}>
					{this.state.uiLoading && <CircularProgress size={150} className={classes.uiProgess} />}
				</main>
			);
		} else {
			return (
				<main className={classes.content}>
				<form onSubmit={this.handleSubmit}>
					<TableContainer component={Paper}>
                          <Table aria-label="customized table">
                            <TableHead>
                              <TableRow>
                                  <TableCell colSpan={4} style={{fontSize:12, backgroundColor: '#071b26', color: 'white'}}>
                                      Colossians 3:23-24: "Whatever you do, work at it
                                      with all your heart, as working for the Lord, not for
                                      human masters, since you know that you will receive an inheritance
                                      from the Lord as a reward. It is the Lord Christ you are serving."
                                  </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                  <TableCell colSpan={2}>
                                      <FormControl className={classes.formControl} style={{minWidth: 75}}>
                                          <Autocomplete
                                            freeSolo
                                            value={this.state.proj}
                                            onChange={this.handleChange}
                                            id="proj"
                                            disableClearable
                                            options={this.state.addresses.map((option) => option.title)}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                label="Project"
                                                margin="normal"
                                                variant="outlined"
                                                onChange={this.handleChange}
                                                InputProps={{ ...params.InputProps, type: 'search', style: {fontSize: 9, minWidth: 150}}}
                                              />
                                            )}
                                          />
                                        </FormControl>
                                  </TableCell>
                                  <TableCell>
                                      <FormControl className={classes.formControl} style={{maxWidth: 250, minWidth: 120}}>
                                          <Autocomplete
                                          freeSolo
                                          id="truc"
                                          disableClearable
                                          options={this.state.trucks.map((option) => option.title)}
                                          value={this.state.truc}
                                          onChange={this.handleChange}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              label="Truck ID"
                                              margin="normal"
                                              variant="outlined"
                                              onChange={this.handleChange}
                                              InputProps={{ ...params.InputProps, type: 'search', style: {fontSize: 9}}}
                                            />
                                          )}
                                        />
                                        </FormControl>
                                  </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell colSpan={2}>
                                  <FormControl className={classes.formControl} style={{maxWidth: 200, minWidth: 75}}>
                                      <Autocomplete
                                          freeSolo
                                          id="lfro"
                                          disableClearable
                                          options={this.state.addresses.map((option) => option.title)}
                                          value={this.state.lfro}
                                          onChange={this.handleChange}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              label="Loaded From"
                                              margin="normal"
                                              variant="outlined"
                                              onChange={this.handleChange}
                                              InputProps={{ ...params.InputProps, type: 'search' , style: {minWidth: 150, fontSize: 9}}}
                                            />
                                          )}
                                        />
                                    </FormControl>
                                </TableCell>
                                <TableCell colSpan={1}>
                                  <FormControl className={classes.formControl} style={{maxWidth: 200, minWidth: 120}}>
                                      <Autocomplete
                                          freeSolo
                                          id="deli"
                                          disableClearable
                                          options={this.state.addresses.map((option) => option.title)}
                                          value={this.state.deli}
                                          onChange={this.handleChange}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              label="Destination"
                                              margin="normal"
                                              variant="outlined"
                                              onChange={this.handleChange}
                                              InputProps={{ ...params.InputProps, type: 'search', style: {fontSize: 9} }}
                                            />
                                          )}
                                        />
                                    </FormControl>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell colSpan={2}>
                                  <FormControl className={classes.formControl} style={{maxWidth: 250, minWidth: 90}}>
                                      <Autocomplete
                                      freeSolo
                                      id="mate"
                                      disableClearable
                                      options={this.state.materials}
                                      value={this.state.mate}
                                      onChange={this.handleChange}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Material"
                                          margin="normal"
                                          variant="outlined"
                                          onChange={this.handleChange}
                                          InputProps={{ ...params.InputProps, type: 'search', style: {fontSize: 11, minWidth: 150}}}
                                        />
                                      )}
                                    />
                                    </FormControl>
                              </TableCell>
                              <TableCell>
                                <TextField id="load-count" label="Load Count" type="number" value={this.state.load} onChange={this.handleChange} />
                              </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  Standby:
                                </TableCell>
                                <TableCell>
                                  <TextField id="standby-hour" label="Hours" type="number" value={this.state.standbyHour} onChange={this.handleChange} />
                                </TableCell>
                                <TableCell>
                                  <TextField id="standby-min" label="Minutes" type="number" value={this.state.standbyMin} onChange={this.handleChange} />
                                </TableCell>
                              </TableRow>
                              {isAdmin ?
                              <TableRow>
                                <TableCell></TableCell>
                                <TableCell style={{textAlign: 'right'}}>Set User:</TableCell>
                                <TableCell>
                                    <FormControl className={classes.formControl} style={{maxWidth: 250, minWidth: 90}}>
                                      <Autocomplete
                                      freeSolo
                                      id="user-setter"
                                      disableClearable
                                      options={this.state.users.map((option) => option.name)}
                                      value={this.state.user}
                                      onChange={this.handleChange}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="User"
                                          margin="normal"
                                          variant="outlined"
                                          onChange={this.handleChange}
                                          InputProps={{ ...params.InputProps, type: 'search', style: {fontSize: 11, minWidth: 150}}}
                                        />
                                      )}
                                    />
                                    </FormControl>
                                </TableCell>
                              </TableRow>
                              : <></> }
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <br/>
                        <div className={classes.centering}>
                        <Button variant="contained"
                            onClick={this.handleSubmit}
                            style={{width:'250px', height:'50px'}}
                            disabled={!this.state.proj ||
                                !this.state.truc ||
                                !this.state.lfro ||
                                !this.state.deli ||
                                !this.state.mate ||
                                !this.state.load}>
                                    Submit
                        </Button>
                        </div>
                  </form>
				</main>
			);
		}
	}
}

export default withStyles(styles)(todo);