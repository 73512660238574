import React, { Component } from 'react';
import firebase from "../firebase";

import withStyles from '@material-ui/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import TimePicker from '@material-ui/lab/TimePicker';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/core/Autocomplete';
import SignatureCanvas from 'react-signature-canvas';
import Signature from './Signature/Signature.js'
import CancelIcon from '@material-ui/icons/Cancel';
import { roundTimeQuarterHour } from '../components/forms/formsService';
import Box from '@material-ui/core/Box';

import { AuthContext } from '../Auth.js';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

const styles = (theme) => ({
	content: {
		margin: 'auto',
        width: '100%',
        //border: '3px solid blue',
        padding: '0px'
	},
	uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		left: '50%',
		top: '35%'
	},
	formControl: {
        minWidth: 50
      },
    centering: {
          display: 'flex',
          justifyContent: 'center'
    },
    autoCompleteText: {
        fontSize: "3px"
    }
});

function createData(date, proj, mat, load, id, hour, min, to, from) {
    return {"date": date, "project": proj, "material": mat, "load": load, "id": id, "hour": hour, "min": min, "deliveredTo": to, "loadedFrom": from};
}

class UserTableRow extends React.Component {
  static contextType = AuthContext;
  constructor(props) {
      super(props);

      this.handleCancel = this.handleCancel.bind(this);
  }

  async handleCancel(event) {
    await this.removeFromPending(this.props.user.id);
    await this.props.handler();
  }

  async removeFromPending(id) {
    const { user } = this.props;
    console.log(user);
    const db = firebase.firestore();
    await db.collection("pendingLoad").doc(id).delete();
    const res = await db.collection('users').doc(this.context.currentUser.email).collection("loads")
        .where('id', '==', user.id);
    res.get().then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
            console.log("DELETING");
            doc.ref.delete();
        });
    });
    //const ret = await db.collection('pendingLoad').where('date', '==', today).where('user', '==', this.context.currentUser.email).get();
    //const res = await db.collection('users').doc(this.context.currentUser.email).collection("loads").add(data);
  }

  render() {
    const { user } = this.props;
    return [
    <>
      <TableRow key="main" >
        <td >{user.material}</td>
        <td width="1px">{user.load}</td>
        <td width="1px">{user.hour}hr {user.min}min</td>
        <td width="1px">
            <Button onClick={this.handleCancel} style={{color:"#b33f3d"}}>
                <CancelIcon/>
            </Button>
        </td>
      </TableRow>
      </>
    ];
  }
}

class endOfDay extends Component {
	constructor(props) {
		super(props);
		this.myRef1 = React.createRef();
		this.myRef2 = React.createRef();

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handler = this.handler.bind(this);
        this.handleDayChange = this.handleDayChange.bind(this);
	}

	static contextType = AuthContext;

	state = { users: [],
	    selectedProject: "",
	    projects: [],
	    time3: dayjs(),
	    time4: dayjs(),
	    day: dayjs().format('MM/DD/YYYY')}

    async componentWillMount() {
        dayjs.extend(relativeTime);
        let date = this.state.day ? this.state.day : dayjs().format('MM/DD/YYYY');
        console.log(this.state.day);
        const db = firebase.firestore();
        const ret = await db.collection('pendingLoad').where('date', '==', date).where('user', '==', this.context.currentUser.email).get();
        let hardData = {};
        const set = new Set();
        ret.forEach(doc => {
          const temp = doc.data();
          temp.id = doc.id;
          if (temp.date == date) {
            set.add(temp.project);
            if (!(temp.project in hardData)) {
                hardData[temp.project] = [];
            }
            console.log(temp);
            hardData[temp.project].push(createData(
                temp.date,
                temp.project,
                temp.material,
                temp.loadCount,
                temp.id,
                temp.standbyHour,
                temp.standbyMin,
                temp.deliveredTo,
                temp.loadedFrom));
          }
        });
        for (const project in hardData) {
            hardData[project].sort(function(a,b){
                 if (a.material < b.material) { return -1; }
                 if (a.material > b.material) { return  1; }
                 return 0;
            });
        }
        if (this.state.selectedProject in hardData) {
            this.setState({users: hardData[this.state.selectedProject] || [], projects: [...set], allData: hardData});
        } else {
            this.setState({users: [], projects: [...set], allData: hardData});
        }



//        const dateSubmitted = await db.collection('users').doc(this.context.currentUser.email).get();
//        if (dateSubmitted.data().dateSubmitted == today) {
//            alert("You have already completed today's End of Day Signoff")
//            this.props.history.push('/endScreen');
//        }
    };

	async handleSubmit(event) {
	    event.preventDefault();
        const db = firebase.firestore();
        var date = this.state.day ? this.state.day : dayjs().format('MM/DD/YYYY');

        const totalTime2 = ((new Date(this.state.time4) - new Date(this.state.time3))/3600000).toFixed(2);
        if (totalTime2 < 0) {
            alert("Time entered is negative!");
            return;
        }
        console.log(totalTime2);
        let id = this.state.users[0].id;
        try {
            const name = await db.collection('users').doc(this.context.currentUser.email).get();
            await db.collection('users').doc(this.context.currentUser.email).collection("hours").add({
                date: date,
                project: this.state.project,
                truckingTime: totalTime2,
            });
        } catch {
            console.log("HOURS UPDATE FAILED BECAUSE LOAD IS ALREADY APPROVED!!!");
        }
        const data = {
            date: date,
            project: this.state.selectedProject,
            time3: this.state.time3.toString(),
            time4: this.state.time4.toString(),
            driver: this.myRef1.current.getTrimmedCanvas().toDataURL("image/png"),
            supervisor: this.myRef2.current.getTrimmedCanvas().toDataURL("image/png"),
            truckingTimeHr: parseInt(totalTime2),
            truckingTimeMin: ((totalTime2 - parseInt(totalTime2)) * 60).toFixed(0)
        }
        console.log(data);
        await db.collection('endOfDay').add(data);
        await db.collection('users').doc(this.context.currentUser.email).collection("endOfDay").add(data);

        var pendingToUpdate = await db.collection('pendingLoad').doc(this.state.users[0].id).update({hours: totalTime2});
        alert("Successful Submit!");
        window.location.reload(false);
    }

    handleChange = (value, newEvent) => {
        this.setState({[value.target.id]: value.target.value});
    }

    handleChangeProjects = (value, newEvent) => {
        if (this.state.newDay) {
            this.setState({selectedProject: value.target.value, newDay: false});
            this.componentWillMount();
        } else {
            this.setState({users: this.state.allData[value.target.value], selectedProject: value.target.value})
        }
    }

    handleDateChange3 = (date) => {
        const time = roundTimeQuarterHour(date);
        this.setState({time3: time});
    }

    handleDateChange4 = (date) => {
        const time = roundTimeQuarterHour(date);
        this.setState({time4: time});
    }

    async handleDayChange(day) {
        console.log(day.target.value)
        await this.setState({day: day.target.value, selectedProject: "", projects: [], newDay: true});
        console.log(this.state.day);
        this.componentWillMount();
    }

    async handler() {
        this.componentWillMount();
    }

	render() {
		dayjs.extend(relativeTime);
		var today = dayjs().format('MM/DD/YYYY');
		const { classes } = this.props;
		const users = this.state.users;
		const projects = this.state.projects;
		console.log(projects);
		const selectedProject = this.state.selectedProject;
		const time3 = this.state.time3;
		const time4 = this.state.time4;
		const isLoading = users === null;
		const emptyLoads = isLoading || users.length == 0;
        const clear1 = () => this.myRef1.current.clear();
        const clear2 = () => this.myRef2.current.clear();

        return (
            <main className={classes.content}>
            <form onSubmit={this.handleSubmit}>
                <TableContainer component={Paper}>
                      <Table aria-label="customized table">
                        <TableHead>
                          <TableRow>
                            <TableCell style={{backgroundColor:'#071b26', color: 'white',}} align="center" colSpan={4}>Today's Summary Form</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell colSpan={4}>
                                <Box sx={{ width:'100%' }}>
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Project</InputLabel>
                                    <Select
                                      labelId="demo-simple-select-"
                                      id="demo-simple-select"
                                      label="Projects"
                                      value={selectedProject}
                                      onChange={this.handleChangeProjects}
                                    >
                                      {isLoading
                                       ? <tr><td colSpan={4} className="uk-text-center"><em className="uk-text-muted">Loading...</em></td></tr>
                                       : projects.map((project, index) => <MenuItem value={project}>{project}</MenuItem>)}
                                    </Select>
                                  </FormControl>
                                </Box>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell colSpan={4}>
                                <Box sx={{ width:'100%' }}>
                                  <FormControl fullWidth>
                                    <TextField
                                      labelId="demo-simple-select-"
                                      id="demo"
                                      label="Date"
                                      value={this.state.day}
                                      onChange={this.handleDayChange}
                                    />
                                  </FormControl>
                                </Box>
                            </TableCell>
                          </TableRow>
                          {isLoading || emptyLoads
                               ? <div></div>
                               : <><TableRow>
                                   <TableCell rowSpan={2} colSpan={4} style={{textAlign:'center',fontSize:18}}>
                                       List of loads completed today:
                                   </TableCell>
                                 </TableRow>

                                 <TableRow>
                                 </TableRow>
                                 <TableRow>
                                 </TableRow>
                                 <tr>
                                   <th >Material</th>
                                   <th >Count</th>
                                   <th >Standby</th>
                                   <th style={{width:'1px'}}/>
                                 </tr></>}
                          {isLoading
                            ? <tr><td colSpan={4} className="uk-text-center"><em className="uk-text-muted">Loading...</em></td></tr>
                            : users.map((user, index) =>
                                <UserTableRow key={index} user={user} handler={this.handler}/>
                              )
                          }
                          {
//                        <TableRow>
//                          <TableCell rowSpan={1} colSpan={2} style={{textAlign:'center',fontSize:12}}>
//                          Driver Time In <br/> (Optional)
//                          <br/>
//                          <LocalizationProvider dateAdapter={AdapterDateFns}>
//                            <TimePicker
//                              label=""
//                              value={time}
//                              onChange={this.handleDateChange}
//                              renderInput={(params) => <TextField {...params} />}
//                            />
//                          </LocalizationProvider>
//                          </TableCell>
//                          <TableCell rowSpan={1} colSpan={2} style={{textAlign:'center',fontSize:12}}>
//                            Driver Time Out <br/> (Optional)
//                            <br/>
//                            <LocalizationProvider dateAdapter={AdapterDateFns}>
//                              <TimePicker
//                                label=""
//                                value={time2}
//                                onChange={this.handleDateChange2}
//                                renderInput={(params) => <TextField {...params} />}
//                              />
//                            </LocalizationProvider>
//                            </TableCell>
//                          </TableRow>
}
                            <TableRow>
                            <TableCell rowSpan={1} colSpan={2} style={{textAlign:'center',fontSize:12}}>
                              Trucking Time In
                              <br/>
                              <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <TimePicker
                                  label=""
                                  value={time3}
                                  onChange={this.handleDateChange3}
                                  renderInput={(params) => <TextField {...params} />}
                                />
                              </LocalizationProvider>
                              </TableCell>
                              <TableCell rowSpan={1} colSpan={2} style={{textAlign:'center',fontSize:12}}>
                                Trucking Time Out
                                <br/>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                  <TimePicker
                                    label=""
                                    value={time4}
                                    onChange={this.handleDateChange4}
                                    renderInput={(params) => <TextField {...params} />}
                                  />
                                </LocalizationProvider>
                                </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell colSpan={4} align="center" style={{position: "relative"}}>
                                    Driver Signature <br/>
                                    <SignatureCanvas backgroundColor='silver' ref={this.myRef1}
                                        canvasProps={{width: 200, height: 75, className: 'sigCanvas', id: "sig1"}} />
                                        <Button onClick={clear1}
                                            style={{color:"#b33f3d",
                                            margin: "0px",
                                            position: "absolute",
                                            top:"50%",
                                            transform: "translate(-75%, -75%)"}}>
                                                <CancelIcon/>
                                        </Button>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={4} align="center" style={{position: "relative"}}>
                                    Approver Signature <br/>
                                    <SignatureCanvas backgroundColor='silver' ref={this.myRef2}
                                        canvasProps={{width: 200, height: 75, className: 'sigCanvas'}} />
                                    <Button onClick={clear2}
                                        style={{color:"#b33f3d",
                                        margin: "0px",
                                        position: "absolute",
                                        top:"50%",
                                        transform: "translate(-75%, -75%)"}}>
                                            <CancelIcon/>
                                    </Button>
                                    </TableCell>
                                </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <br/>
                    <div className={classes.centering}>
                        <Button variant="contained"
                        style={{width:'250px', height:'50px'}}
                        onClick={this.handleSubmit}
                            disabled={
                                !this.state.time3 ||
                                !this.state.time4
                                }>
                                 Submit
                        </Button>
                    </div>
              </form>
            </main>
        );
	}
}

export default withStyles(styles)(endOfDay);