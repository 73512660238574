import React, { Component } from 'react';

import Todo from '../components/forms/todo';

import { AuthContext } from '../Auth.js';
import { Navigate } from "react-router-dom";
import withStyles from '@material-ui/styles/withStyles';

const drawerWidth = 240;

const styles = (theme) => ({
	root: {display: 'flex'},
	appBar: {zIndex: theme.zIndex.drawer + 1},
	drawer: {
		width: drawerWidth,
		flexShrink: 0
	},
	drawerPaper: {width: drawerWidth},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3)
	},
	avatar: {
		height: 110,
		width: 100,
		flexShrink: 0,
		flexGrow: 0,
		marginTop: 20
	},
	uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		left: '45%',
		top: '35%'
	},
	toolbar: theme.mixins.toolbar
});

class Home extends Component {

	constructor(props) {
		super(props);

		this.mounted = false;
	}
    static contextType = AuthContext;

	componentDidMount() {
        this.mounted = true;
        const value = this.context;
        if (!value.currentUser) {
          return (<Navigate to="/login" />);
        }
	};

	componentWillUnmount() {
        this.mounted = false;
    }

    componentDidUpdate() {
        const value = this.context;
        if (!value.currentUser) {
            return (<Navigate to="/login" />);
        }
    }


	render() {
	    if (!this.context.currentUser) {
            return (<Navigate to="/login" />);
        }
		const { classes } = this.props;
			return (
				<div className={classes.root}>
					<div>
					    <Todo />
                    </div>
				</div>
			);
	}
}

export default withStyles(styles)(Home);