import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/styles';
import firebase from 'firebase/app';
import 'firebase/firestore';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  dialog: {
    maxWidth: 350 + 'px',
    minWidth: 290 + 'px',
    margin: 'auto',
  },
  progess: {
    position: 'absolute',
  },
}));

export default function ChangeName(props) {
  const classes = useStyles();
  const [firstName, setFirstName] = React.useState(props.firstName);
  const [lastName, setLastName] = React.useState(props.lastName);
  const [loading, setLoading] = React.useState(false);
  let mounted = React.useRef(false);

  React.useEffect(() => {
    mounted.current = true;
    return () => (mounted.current = false);
  });

  const submit = async (event) => {
    if (mounted.current) {
      setLoading(true);
    }

    await firebase
      .firestore()
      .collection('users')
      .doc(props.currentUser.uid)
      .update({
        firstName: firstName,
        lastName: lastName,
      });
    if (mounted.current) {
      setLoading(false);
    }

    if (mounted.current) {
      props.updateName(firstName, lastName);
      props.close();
    }
  };

  return (
    <div>
      <form noValidate>
        <Dialog
          className={classes.dialog}
          open={props.open}
          onClose={props.close}
          aria-labelledby='form-dialog-title'>
          <DialogTitle id='form-dialog-title'>Change name</DialogTitle>

          <DialogContent>
            <TextField
              autoFocus
              fullWidth={true}
              margin='dense'
              name='firstName'
              label='First name'
              variant='outlined'
              InputLabelProps={{
                shrink: true,
              }}
              defaultValue={firstName}
              onChange={(event) => {
                setFirstName(event.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  submit();
                }
              }}
            />
            <TextField
              fullWidth={true}
              margin='dense'
              name='lastName'
              label='Last name'
              variant='outlined'
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  submit();
                }
              }}
              InputLabelProps={{
                shrink: true,
              }}
              defaultValue={lastName}
              onChange={(event) => setLastName(event.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={props.close} color='primary'>
              Cancel
            </Button>
            <Button
              type='submit'
              variant='outlined'
              onClick={submit}
              color='primary'>
              Submit
              {loading && (
                <CircularProgress size={30} className={classes.progess} />
              )}
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
}
